<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.diplomas">
			<template #title>Diplomas</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'DiplomasNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>

			<v-data-table :headers="headers" :items="diplomas" item-key="name" :search="search" @click:row="editDiploma" :item-class="() => 'hover-pointer'">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>

				</template>
				<template v-slot:item.actions="{ item }">
					<v-btn icon @click="editDiploma(item.id)">
						<v-icon>{{$icons.edit}}</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "DiplomasIndex",
	computed:{
		diplomas: sync('diplomas/diplomas')
	},
	data: () => ({
		search: '',
		headers:[
			{
				text: 'Name',
				align: 'start',
				sortable: true,
				value: 'name',
			},
		]
	}),
	beforeMount(){
		this.init()
	},
	methods:{
		init(){
			this.$store.set('diplomas/getDiplomas!', null)
		},
		editDiploma(data){
			this.$router.push({name:'DiplomasEdit', params: {id: data.id}})
		},
		filterOnlyCapsText (value, search, item) {
			return value != null &&
				search != null &&
				typeof value === 'string' &&
				value.toString().toLocaleUpperCase().indexOf(search) !== -1
		},
	}
}
</script>